import React, {useEffect} from 'react';
import Footer from '../../components/navigation/footer/Footer'; 
import './homepage.css'; 
import Logo from '../../components/navigation/header/Logo';

function HomePage() {
    useEffect(() => {
        document.title = "Arena Negra | músico uruguayo"
     }, []);
    return (
        <div className='homepage'> 
            <div>
                <header> 
                    <Logo src="../arena-negra-logo.svg" className="logo" />
                </header>
                <main className='Album'>
                    <div> 
                        <div className='album-cover'>
                            <figure><img src="./images/entre-mundos-y-libertad.jpg" alt="álbum" /></figure>
                            <a href="/album/entre-mundos-y-libertad">Escuchar álbum</a>
                        </div>
                        <p>(2023)</p>
                    </div>
                    <div>
                         
                        <div className='album-cover'>
                            <figure><img src="./images/album-arena-negra.jpeg" alt="álbum" /></figure>
                            <a href="/album/arena-negra">Escuchar álbum</a>
                        </div>
                        <p>(2019)</p>
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    )
}

export default HomePage
