import React, { useEffect } from 'react'
import Footer from '../../components/navigation/footer/Footer'
import Logo from '../../components/navigation/header/Logo'

function EntreMundosYLibertad() {
    useEffect(() => {
        document.title = "Arena Negra | Entre mundos y libertad"
    }, []);
    return (
        <div className='listen-album EntreMundosYLibertad'>
            <div className='background-frosted'>
                <div>
                    <header><Logo src="../arena-nerga-logo-horizontal.svg" className="logo" /> </header>
                    <main className='music-platforms'>
                        <div className='album-details'>
                            <h1>Entre mundos y libertad</h1>
                            <p>24 de julio de 2023</p>
                        </div>
                        <ul>
                            <li className='album-cover'>
                                <figure><img src="../images/entre-mundos-y-libertad.jpg" alt="álbum" /></figure>
                            </li> 
                            <li className='platforms-list'>
                                <figure><img src="../images/music-platforms/spotify.svg" alt="Spotify" /></figure>
                                <a className='buttons' href="https://open.spotify.com/album/1n9YC6uMXWbWjl8JoCd1BN" rel="noreferrer" target="_blank">ESCUCHAR</a>
                                <figure><img src="../images/music-platforms/apple-music.svg" alt="Apple Music" /></figure>
                                <a className='buttons' href='https://music.apple.com/us/album/entre-mundos-y-libertad/1694801985' rel="noreferrer" target="_blank">ESCUCHAR</a>
                                <figure><img src="../images/music-platforms/youtube-music.svg" alt="YouTube Music" /></figure>
                                <a className='buttons' href='https://music.youtube.com/playlist?list=OLAK5uy_ldqqzc8Ylaqqh633KlAfzJag5_2EaZaHE' rel="noreferrer" target="_blank" >ESCUCHAR</a>
                                <figure><img src="../images/music-platforms/amazon-music.svg" alt="Amazon Music" /></figure>
                                <a className='buttons' href='https://music.amazon.com/albums/B0C9NTM767' rel="noreferrer" target="_blank">ESCUCHAR</a>
                            </li>
                        </ul>
                        <div className='album-details'><p>© 2023 Gianni Arena <br/>
                            ℗ 2023 Gianni Arena</p></div>

                    </main>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default EntreMundosYLibertad
