import HomePage from './pages/homepage/HomePage';
import './App.css';
import { Routes, Route } from "react-router-dom";  
import Album from './pages/album/Album';
import BlackSand from './pages/album/BlackSand';
import Error404 from './pages/error404/Error404';
import Explore from './explore/Explore';


function App() {
  return ( 
     <Routes>
        {/* Página de Inicio */}
        <Route path={`/`} element={<HomePage />} />  
        {/* Not Found Page */}
        <Route path={`/album/*`} element={<Album />} />   
        <Route path={`/album-arena-negra`} element={<BlackSand />} />
        <Route path={`/explora`} element={<Explore />} />
        <Route path="*" element={<Error404 />} />
      </Routes> 
  );
}

export default App;
