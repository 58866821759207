import React from 'react'
import { Routes, Route } from 'react-router-dom'
import BlackSand from './BlackSand'  
import EntreMundosYLibertad from './EntreMundosYLibertad'

const Album = () => {
    return ( 
        <Routes>
            <Route path="arena-negra" element={<BlackSand />} />
            <Route path="entre-mundos-y-libertad" element={<EntreMundosYLibertad />} />
        </Routes>
                     
    )
}

export default Album
