import React, { useEffect } from 'react'
import './album.css'
import Footer from '../../components/navigation/footer/Footer';
import Logo from '../../components/navigation/header/Logo';

function BlackSand() {
    useEffect(() => {
        document.title = "Escuchar Arena Negra"
    }, []);
    return (
        <div className='listen-album'>
            <div className='background-frosted'>  
                <div>
                    <header><Logo src="../arena-nerga-logo-horizontal.svg" className="logo" /> </header>
                    <main className='music-platforms'>
                        <div className='album-details'>
                            <h1>Arena Negra </h1>
                            <p>9 de septiembre de 2019</p>
                             
                        </div>
                        <ul>
                            <li className='album-cover'>
                                <figure><img src="../images/album-arena-negra.jpeg" alt="álbum" /></figure>
                            </li>
                            <li className='platforms-list'>
                                <figure><img src="../images/music-platforms/spotify.svg" alt="Spotify" /></figure>
                                <a className='buttons' href="https://open.spotify.com/artist/1MX8lmEO8yfRymlZjaasoM?si=zyCdRhCLQf-GyBODLoKovw" rel="noreferrer" target="_blank">ESCUCHAR</a>
                                <figure><img src="../images/music-platforms/apple-music.svg" alt="Apple Music" /></figure>
                                <a className='buttons' href='https://music.apple.com/us/artist/arena-negra/1479109149' rel="noreferrer" target="_blank">ESCUCHAR</a>
                                <figure><img src="../images/music-platforms/youtube-music.svg" alt="YouTube Music" /></figure>
                                <a className='buttons' href='https://music.youtube.com/channel/UCcduXa1joRMj44r_IBQMaWw?feature=share' rel="noreferrer" target="_blank" >ESCUCHAR</a>
                                <figure><img src="../images/music-platforms/amazon-music.svg" alt="Amazon Music" /></figure>
                                <a className='buttons' href='https://music.amazon.com/artists/B07XJB7Q3B/arena-negra' rel="noreferrer" target="_blank">ESCUCHAR</a>
                            </li>
                        </ul>
                        <div className='album-details'><p>© 2019 Gianni Arena <br/>
                            ℗ 2019 Gianni Arena</p></div>

                    </main>
                    <Footer />
                </div> 
            </div> 
        </div>
    )
}

export default BlackSand
